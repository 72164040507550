import React, { useState} from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import logo from '../assets/logo.png'
import {HashLink as Link} from 'react-router-hash-link'
import { Dialog, DialogPanel } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
    { name: 'About Us', href: '#aboutUs' },
    { name: 'Our Services', href: '#ourServices' },
    { name: 'Team', href: '#team' },
    { name: 'Contact Us', href: '#contactUs' },
  ]

const Navbar = () => {
const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div>
    <nav aria-label="Global" className="flex items-center justify-between">
      <Link smooth to='#home'>
    <img
          alt=""
          src={logo}
          className="h-14 w-44 py-0 "
    />
    </Link>

        <div className='p-6 lg:px-8'>
    <div className="flex lg:hidden">
      <button
        type="button"
        onClick={() => setMobileMenuOpen(true)}
        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
      >
        <span className="sr-only">Open main menu</span>
        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
      </button>
    </div>
    <div className="hidden lg:flex lg:gap-x-12">
      {navigation.map((item) => (
        <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
          <Link smooth to={item.href}>{item.name}</Link>
        </a>
      ))}
    </div>
    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
    </div>
    </div>
  </nav>
  <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog></div>
  )
}

export default Navbar;